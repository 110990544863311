import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>

  </header>
)

// <nav>
//   <h1 className="nav__title">
//     <Link to="/" className="nav__link">
//       {siteTitle}
//     </Link>
//   </h1>
//   <ul className="nav__list">
//     <li className="nav__item"><a href="#offer"> Oferta</a></li>
//     <li className="nav__item"><a href="#contact">Kontakt</a></li>
//   </ul>
// </nav>

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
