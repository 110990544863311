import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => {
  const [showProjects, setShowProjects] = useState(false)

  return (
    <Layout>
      <SEO
        title="Infovation"
        keywords={[
          "gatsby",
          "Infovation",
          "React",
          "Bielsko-Biała",
          "strony wizytówkowe",
          "cms",
          "strony www",
          "Ruby on Rails",
          "web development",
          "serwisy internetowe",
        ]}
      />
      <div className="main__info">Infovation</div>
      <div className="main__info--small">Your IT innovation</div>
      <div className="main__info--contact">
        <a href="mailto:hi@infovation.pl" target="_top">
          hi@infovation.pl
        </a>
      </div>
      {/* <div className="main__info--contact">
        <a href="tel:518 059 787" target="_top">518 059 787</a>
      </div> */}
      <div className="main__info--contact">Bielsko-Biała, Polska/Poland</div>
      <div className="technology">
        <h1>Technologie:</h1>
        <ul>
          <li>Ruby on Rails</li>
          <li>ReactJS</li>
          <li>Tailwindcss</li>
          <li>React Native</li>
          <li>Javascript</li>
          <li>Next.js</li>
          <li>Gatsby</li>
        </ul>
      </div>
      <div className="offer">
        <h1>Oferta:</h1>
        <ul>
          <li>Portale internetowe</li>
          <li>Strony wizytówkowe</li>
          <li>Strony CMS</li>
          <li>Serwisy internetowe</li>
          <li>Aplikacje mobilne</li>
          <li>Web development</li>
          <li>Konsultacje IT</li>
          <li>Audyt rozwiązań IT</li>
          <li>Projektowanie stron</li>
          <li>Grafika komputerowa, loga</li>
        </ul>
      </div>
      <div
        id="BNCD-webwidget"
        webkey="3j98117XFhTP4Ca2Dj12urMWF7hi9GM5"
        withImage
        withFilter
        gradient
        mono
        filterColor="#614700"
      ></div>
    </Layout>
  )
}

export default IndexPage
